<template>
    <div class="location-filters filter-section">
        <h3 class="subtitle is-4">
            {{ $t('LOCATION') }}
        </h3>
        <div class="filter-by">
            <h4>{{ $t('FILTER_BY') }}</h4>
            <b-field>
                <b-radio-button
                    v-model="locationFilterType"
                    native-value="province"
                    role="radio"
                    tabindex="705"
                    @keypress.enter.native="locationFilterType = 'province'"
                >
                    {{ $t('PROVINCE_TERRITORY') }}
                </b-radio-button>
                <b-radio-button
                    v-model="locationFilterType"
                    native-value="watershed"
                    role="radio"
                    tabindex="706"
                    @keypress.enter.native="locationFilterType = 'watershed'"
                >
                    {{ $t('WATERSHED') }}
                </b-radio-button>
            </b-field>
        </div>
        <b-taginput
            ref="taginput"
            v-model="selectedRegions"
            :data="filteredTags"
            :placeholder="taginputPlaceholder"
            attached
            autocomplete
            keep-first
            open-on-focus
            tabindex="707"
            class="location-picker"
            @typing="getFilteredTags"
        >
            <template slot-scope="props">
                {{ props.option.name }} ({{ props.option.value.toUpperCase() }})
            </template>
            <template #selected="props">
                <b-tag
                    v-for="(tag, index) in props.tags"
                    :key="tag.id"
                    closable
                    :class="{'large-filter-tag' : `${tag.name} (${tag.value})`.length > 42}"
                    @close="$refs.taginput.removeTag(index, $event)"
                >
                    {{ tag.name }} ({{ tag.value }})
                </b-tag>
            </template>
        </b-taginput>
    </div>
</template>

<script>
import isEmpty from 'lodash.isempty';

export default {
    name: 'LocationFilter',

    props: {
        locationFilters: {
            type: Object,
            required: true,
        },

        featureTypeDefinitions: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        locationFilterType: 'province',
        selectedProvinces: [],
        selectedWatersheds: [],
        filteredProvinceTags: [],
        filteredWatershedTags: [],
        provinceOptions: [],
        watershedOptions: [],
    }),

    computed: {
        filteredTags() {
            if (this.searchByProvince) {
                return this.filteredProvinceTags;
            }

            return this.filteredWatershedTags;
        },

        searchByProvince() {
            return this.locationFilterType === 'province';
        },

        taginputPlaceholder() {
            if (this.searchByProvince) {
                return this.$t('ADD_PROVINCE');
            }

            return this.$t('ADD_WATERSHED');
        },

        selectedRegions: {
            get() {
                if (this.searchByProvince) {
                    return this.selectedProvinces;
                }

                return this.selectedWatersheds;
            },

            set(newValues) {
                if (this.searchByProvince) {
                    this.selectedProvinces = newValues;
                } else {
                    this.selectedWatersheds = newValues;
                }
            },
        },
    },

    watch: {
        selectedRegions: {
            deep: true,
            handler() {
                this.emitUpdatedFilters();
            },
        },

        locationFilterType() {
            this.emitUpdatedFilters();
            this.getFilteredTags();
            if (this.locationFilterType === 'watershed') this.$emit('nhn');
        },

        locationFilters() {
            if (isEmpty(this.locationFilters)) {
                // if the given location filters are empty, we should reset them
                this.locationFilterType = 'province';
                this.selectedProvinces = [];
                this.selectedWatersheds = [];
            } else {
                this.locationFilterType = this.locationFilters.locationType;
                this.selectedProvinces = this.locationFilters.selectedProvinces;
                this.selectedWatersheds = this.locationFilters.selectedWatersheds;
            }
        },
    },

    created() {
        const provinceAttribute = this.featureTypeDefinitions.barriers.attributes.find(
            (attribute) => attribute.id === 'province_territory_code',
        );
        this.provinceOptions = provinceAttribute.values;

        const watershedAttribute = this.featureTypeDefinitions.barriers.attributes.find(
            (attribute) => attribute.id === 'nhn_watershed_id',
        );
        this.watershedOptions = watershedAttribute?.values;
    },

    mounted() {
        if (!isEmpty(this.locationFilters)) {
            this.locationFilterType = this.locationFilters.locationType;
            this.selectedProvinces = this.locationFilters.selectedProvinces;
            this.selectedWatersheds = this.locationFilters.selectedWatersheds;
        }

        this.getFilteredTags();
    },

    methods: {
        /**
         * Filter the region options by the given text.
         *
         * @param {string} text - text to base filter on
         */
        getFilteredTags(text) {
            if (this.searchByProvince) {
                this.filteredProvinceTags = this.provinceOptions.filter((option) => {
                    const alreadySelected = this.selectedProvinces.some(
                        (selectedProvinces) => selectedProvinces.value === option.value,
                    );

                    if (!text) {
                        return !alreadySelected;
                    }

                    const nameMatches = option.name.toLowerCase().includes(text.toLowerCase());
                    const abbrMatches = option.value.toLowerCase().includes(text.toLowerCase());

                    return (nameMatches || abbrMatches) && !alreadySelected;
                });
            } else {
                this.filteredWatershedTags = this.watershedOptions.filter((option) => {
                    const alreadySelected = this.selectedWatersheds.some(
                        (selectedWatershed) => selectedWatershed.value === option.value,
                    );

                    if (!text) {
                        return !alreadySelected;
                    }

                    const nameMatches = option.name?.toLowerCase().includes(text.toLowerCase());
                    const idMatches = option.value.toLowerCase().includes(text.toLowerCase());

                    return (nameMatches || idMatches) && !alreadySelected;
                });
            }
        },

        /**
         * Emit the current filters. Should be called when the filters are updated.
         */
        emitUpdatedFilters() {
            this.$emit('updated', {
                locationType: this.locationFilterType,
                selectedProvinces: this.selectedProvinces,
                selectedWatersheds: this.selectedWatersheds,
                regions: this.selectedRegions,
            });
        },
    },
};
</script>

<style lang="scss">
.location-filters {
    .filter-by {
        display: grid;
        grid-template-rows: repeat(2, auto);
        gap: 0.5rem 1rem;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        .field .control {
            outline: none;

            &:focus-visible {
                .button {
                    outline: 3px solid $element-focus;
                    border-color: transparent;
                    z-index: 10;
                }
            }
        }

        // stylelint-disable-next-line no-descending-specificity
        .button {
            font-size: 0.9rem;
            padding: 0.5em;
        }
    }

    .large-filter-tag {
        height: 3em !important;
        white-space: normal;
    }

    .location-picker {
        .taginput-container.is-focusable {
            border-width: 2px;

            &.is-focused {
                border-color: $primary;
                box-shadow: none;
            }
        }

        .auto-complete.control {
            width: 100%;
        }
    }

    @include tablet {
        .filter-by {
            font-size: 1rem;
            grid-template-columns: auto 1fr;
            grid-template-rows: auto;

            .button {
                font-size: 1rem;
            }
        }
    }
}
</style>
