import { CompositeLayer } from '@deck.gl/core';
import { GeoJsonLayer } from '@deck.gl/layers';

class A11yCompositeLayer extends CompositeLayer {
    renderLayers() {
        return [
            new GeoJsonLayer(this.getSubLayerProps({
                id: 'icons-outline',
                data: this.props.data,
                pointType: 'text',
                // https://pictogrammers.github.io/@mdi/font/6.9.96/
                getText: this.props.getBottomText,
                textCharacterSet: this.props.bottomTextCharacterSet,
                getTextSize: 3400,
                textSizeMinPixels: 8,
                textSizeMaxPixels: 34,
                textSizeUnits: 'meters',
                textFontFamily: 'Material Design Icons',
                getTextColor: [0, 0, 0],
            })), // basic shape icons
            new GeoJsonLayer(this.getSubLayerProps({
                id: 'icons',
                data: this.props.data,
                pointType: 'text',
                // https://pictogrammers.github.io/@mdi/font/6.9.96/
                getText: this.props.getText,
                textCharacterSet: this.props.textCharacterSet,
                getTextSize: 3000,
                textSizeMinPixels: 6,
                textSizeMaxPixels: 30,
                textSizeUnits: 'meters',
                textFontFamily: 'Material Design Icons',
                getTextColor: [255, 255, 255, 200],
            })), // basic shape icons
            new GeoJsonLayer(this.getSubLayerProps({
                id: `${this.props.id}-text`,
                data: this.props.data,
                pointType: 'text',
                // https://pictogrammers.github.io/@mdi/font/6.9.96/
                getText: (f) => {
                    if (f.properties.passability_status_code === 2) {
                        // partial barrier
                        return '\u{F01D9}'; // three vertical dots
                    }
                    if (f.properties.passability_status_code === 1) {
                        // barrier
                        return '!'; // close
                    }
                    if (f.properties.passability_status_code === 3) {
                        // passable
                        return '\u{F012C}'; // checkmark
                    }
                    if (f.properties.passability_status_code === 5) {
                        // passable
                        return '\u{F0156}'; // close
                    }
                    if (f.properties.passability_status_code === 6) {
                        // passable
                        return '/'; // checkmark
                    }
                    if (f.properties.feature_type === 'fishways') {
                        return '';
                    }
                    return '?';
                },
                textCharacterSet: ['\u{F01D9}', '\u{F0156}', '\u{F012C}', '?', '/', '!'],
                getTextSize: 2000,
                textSizeMinPixels: 4,
                textSizeMaxPixels: 20,
                textSizeUnits: 'meters',
                textFontFamily: 'Material Design Icons',
                getTextColor: [0, 0, 0],
                getPixelOffset: [0, 4],
            })), // text to represent the passability
        ];
    }
}
A11yCompositeLayer.layerName = 'A11ycompositeLayer';

export default A11yCompositeLayer;
