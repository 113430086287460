<template>
    <div class="search">
        <hr>
        <ValidationObserver
            v-slot="{ handleSubmit }"
        >
            <div class="fields">
                <div v-if="selectedSystem.name === 'place-name'">
                    <b-field
                        :label="$t('PLACE')"
                        label-position="inside"
                        horizontal
                        class="form-field"
                    >
                        <b-autocomplete
                            class="geocoder-search"
                            expanded
                            keep-first
                            clear-on-select
                            :data="nameSearchResults"
                            field="place_name"
                            open-on-focus
                            dropdown-position="right"
                            tabindex="101"
                            @typing="searchByName"
                            @select="selectNameSearchOption"
                        >
                            <template slot-scope="props">
                                <div
                                    aria-label="Potential search match"
                                    class="geocoder-search-result"
                                >
                                    <b-icon
                                        type="is-primary"
                                        size="is-small"
                                        pack="mdi"
                                        :icon="props.option.previousLocation ? 'history' : 'map-marker'"
                                    />
                                    <b class="has-text-black">{{ props.option.place_name }}</b>
                                </div>
                            </template>
                        </b-autocomplete>
                    </b-field>
                </div>
                <div v-else-if="selectedSystem.name === 'structure'">
                    <b-field
                        :label="$t('STRUCTURE')"
                        label-position="inside"
                        horizontal
                        class="form-field"
                    >
                        <b-autocomplete
                            class="geocoder-search"
                            expanded
                            keep-first
                            clear-on-select
                            :data="structureSearchResults"
                            field="structure_name"
                            open-on-focus
                            dropdown-position="right"
                            tabindex="101"
                            @typing="searchByStructure"
                            @select="selectStructureSearchOption"
                        >
                            <template slot-scope="props">
                                <div
                                    aria-label="Potential search match"
                                    class="geocoder-search-result"
                                >
                                    <b-icon
                                        size="is-small"
                                        pack="mdi"
                                        :icon="iconTranslate[props.option.properties.feature_type]"
                                        :class="`${props.option.properties.passability_status
                                            || 'undefined'}`
                                            .toLowerCase()
                                            .replace(' ', '_')"
                                    />
                                    <b class="has-text-black">{{ props.option.properties.name_en || props.option.properties.name_fr || $t('UNNAMED') }}</b>
                                </div>
                                <p>
                                    {{ props.option.properties.municipality }},
                                    {{ props.option.properties.province_territory_code.toUpperCase() }}
                                </p>
                            </template>
                        </b-autocomplete>
                    </b-field>
                </div>
                <div v-else-if="selectedSystem.name === 'cabd-id'">
                    <b-field
                        :label="$t('CABD_ID')"
                        label-position="inside"
                        horizontal
                        class="form-field"
                    >
                        <b-autocomplete
                            class="geocoder-search"
                            expanded
                            keep-first
                            clear-on-select
                            :data="idSearchResults"
                            field="cabd_id"
                            open-on-focus
                            dropdown-position="right"
                            tabindex="101"
                            @typing="searchById"
                            @select="selectIdSearchOption"
                        >
                            <template slot-scope="props">
                                <div
                                    aria-label="Potential search match"
                                    class="geocoder-search-result"
                                >
                                    <b-icon
                                        size="is-small"
                                        pack="mdi"
                                        :icon="iconTranslate[props.option.properties.feature_type]"
                                        :class="`${props.option.properties.passability_status
                                            || 'undefined'}`
                                            .toLowerCase()
                                            .replace(' ', '_')"
                                    />
                                    <b class="has-text-black">
                                        {{
                                            props.option.properties.dam_name_en
                                                || props.option.properties.name_fr
                                                || props.option.properties.dam_name_en
                                                || props.option.properties.dam_name_fr
                                                || props.option.properties.fall_name_en
                                                || props.option.properties.fall_name_fr
                                                || props.option.properties.crossing_name_en
                                                || props.option.properties.crossing_name_fr
                                                || props.option.properties.structure_name_en
                                                || props.option.properties.structure_name_fr
                                                || $t('UNNAMED')
                                        }}
                                    </b>
                                </div>
                                <p>
                                    {{ props.option.properties.municipality }},
                                    {{ props.option.properties.province_territory_code.toUpperCase() }}
                                </p>
                            </template>
                        </b-autocomplete>
                    </b-field>
                </div>
                <div v-else-if="selectedSystem.name === 'facility_name'">
                    <b-field
                        :label="$t('FACILITY')"
                        label-position="inside"
                        horizontal
                        class="form-field"
                    >
                        <b-autocomplete
                            class="geocoder-search"
                            expanded
                            keep-first
                            clear-on-select
                            :data="facilitySearchResults"
                            field="facility_name"
                            open-on-focus
                            dropdown-position="right"
                            tabindex="101"
                            @typing="searchByFacility"
                            @select="selectFacilitySearchOption"
                        >
                            <template slot-scope="props">
                                <div
                                    aria-label="Potential search match"
                                    class="geocoder-search-result"
                                >
                                    <b-icon
                                        size="is-small"
                                        pack="mdi"
                                        :icon="iconTranslate[props.option.properties.feature_type]"
                                        :class="`${props.option.properties.passability_status
                                            || 'undefined'}`
                                            .toLowerCase()
                                            .replace(' ', '_')"
                                    />
                                    <b class="has-text-black">
                                        {{
                                            props.option.properties.dam_name_en
                                                || props.option.properties.name_fr
                                                || props.option.properties.dam_name_en
                                                || props.option.properties.dam_name_fr
                                                || props.option.properties.fall_name_en
                                                || props.option.properties.fall_name_fr
                                                || props.option.properties.crossing_name_en
                                                || props.option.properties.crossing_name_fr
                                                || props.option.properties.structure_name_en
                                                || props.option.properties.structure_name_fr
                                                || $t('UNNAMED')
                                        }}
                                    </b>
                                </div>
                                <p>
                                    {{ props.option.properties.facility_name_en
                                        || props.option.properties.facility_name_fr }},
                                    {{ props.option.properties.province_territory_code.toUpperCase() }}
                                </p>
                            </template>
                        </b-autocomplete>
                    </b-field>
                </div>
                <!-- <div v-else-if="selectedSystem.name === 'waterbody_name'">
                    <b-field
                        :label="$t('WATERBODY')"
                        label-position="inside"
                        horizontal
                        class="form-field"
                    >
                        <b-autocomplete
                            class="geocoder-search"
                            expanded
                            keep-first
                            clear-on-select
                            :data="waterbodySearchResults"
                            field="waterbody_name"
                            open-on-focus
                            dropdown-position="right"
                            tabindex="101"
                            @typing="searchByWaterbody"
                            @select="selectWaterbodySearchOption"
                        >
                            <template slot-scope="props">
                                <div
                                    aria-label="Potential search match"
                                    class="geocoder-search-result"
                                >
                                    <b-icon
                                        size="is-small"
                                        pack="mdi"
                                        :icon="iconTranslate[props.option.properties.feature_type]"
                                        :class="`${props.option.properties.passability_status
                                            || 'undefined'}`
                                            .toLowerCase()
                                            .replace(' ', '_')"
                                    />
                                    <b class="has-text-black">
                                        {{
                                            props.option.properties.name_en
                                                || props.option.properties.name_fr
                                                || 'Unnamed'
                                        }}
                                    </b>
                                </div>
                                <p>
                                    {{ props.option.properties.municipality }},
                                    {{ props.option.properties.province_territory_code.toUpperCase() }}
                                </p>
                            </template>
                        </b-autocomplete>
                    </b-field>
                </div> -->
                <div v-else-if="selectedSystem.name === 'lat-lon'">
                    <ValidationProvider
                        v-slot="{ errors }"
                        rules="required|lat_lon"
                        mode="eager"
                    >
                        <b-field
                            :message="errors[0]"
                            :label="$t('LAT_LNG')"
                            label-position="inside"
                            horizontal
                            class="form-field"
                        >
                            <b-input
                                v-model="selectedSystem.inputValue"
                                @keyup.native.enter="handleSubmit(handleFormSubmission)"
                            />
                        </b-field>
                    </ValidationProvider>
                </div>
                <div v-else-if="selectedSystem.name === 'epsg'">
                    <ValidationProvider
                        v-for="(field, index) in selectedSystem.fields"
                        :key="field.label"
                        v-slot="{ errors }"
                        :rules="getValidationRules(field)"
                        mode="eager"
                    >
                        <b-field
                            :type="{ 'is-danger': errors.length }"
                            :message="errors[0]"
                            :label="field.label"
                            label-position="inside"
                            horizontal
                            class="form-field"
                        >
                            <b-input
                                v-model="field.value"
                                :tabindex="101 + index"
                                @keyup.native.enter="handleSubmit(handleFormSubmission)"
                            />
                        </b-field>
                        <hr v-if="index !== selectedSystem.fields.length - 1">
                    </ValidationProvider>
                </div>
            </div>
            <hr>
            <div class="controls">
                <b-button
                    v-show="['epsg', 'lat-lon'].includes(selectedSystemName)"
                    type="is-primary"
                    aria-label="Perform Search"
                    tabindex="110"
                    class="go-button"
                    @click="handleSubmit(handleFormSubmission)"
                >
                    {{ $t('GO') }}
                </b-button>
                <b-select
                    v-model="selectedSystemName"
                    role="listbox"
                    tabindex="111"
                    class="coordinate-system-select"
                >
                    <option
                        v-for="system in searchSystem"
                        :key="system.name"
                        role="option"
                        :value="system.name"
                    >
                        {{ system.label }}
                    </option>
                </b-select>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import axios from 'axios';
import debounce from 'lodash.debounce';
import proj4 from 'proj4';

import validationMixin from '@/mixins/inputValidation';

export default {
    name: 'Search',

    mixins: [
        validationMixin,
    ],

    props: {
        attributeFilters: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            locale: this.$route.params.locale,
            // locale: 'en',
            selectedSystemName: '',
            iconTranslate: {
                dams: 'square',
                fishways: 'fish',
                waterfalls: 'triangle',
            },
            searchSystem: [
                {
                    name: 'place-name',
                    label: this.$t('PLACES'),
                    inputValue: '',
                },
                {
                    name: 'structure',
                    label: this.$t('STRUCTURE_NAME'),
                    inputValue: '',
                },
                {
                    name: 'cabd-id',
                    label: this.$t('CABD_ID'),
                    inputValue: '',
                },
                {
                    name: 'facility_name',
                    label: this.$t('FACILITY_NAME'),
                    inputValue: '',
                },
                // {
                //     name: 'waterbody_name',
                //     label: this.$t('WATERBODY'),
                //     inputValue: '',
                // },
                {
                    name: 'lat-lon',
                    label: this.$t('LAT_LNG'),
                    inputValue: '',
                },
                {
                    name: 'epsg',
                    label: this.$t('EPSG'),
                    fields: [
                        {
                            name: 'epsg-code',
                            label: this.$t('EPSG_CODE'),
                            value: null,
                            min: 1024,
                            max: 32767,
                        },
                        {
                            name: 'x',
                            label: this.$t('X_COORD'),
                            value: null,
                        },
                        {
                            name: 'y',
                            label: this.$t('Y_COORD'),
                            value: null,
                        },
                    ],
                },
            ],
            nameSearchResults: [],
            structureSearchResults: [],
            facilitySearchResults: [],
            waterbodySearchResults: [],
            idSearchResults: [],
            abortController: new AbortController(),
            isFetching: false,
        };
    },

    computed: {
        selectedSystem() {
            return this.searchSystem.find((system) => system.name === this.selectedSystemName);
        },
    },

    beforeMount() {
        this.$i18n.locale = this.$route.params.locale;
        this.selectedSystemName = this.searchSystem[0].name;
    },

    methods: {
        /**
         * Formats coordinates correctly and emits 'location-searched' event containing the coordinates.
         */
        handleFormSubmission() {
            let coordinates;
            const { fields } = this.selectedSystem;
            if (this.selectedSystemName === 'lat-lon') {
                const [lat, lon] = this.selectedSystem.inputValue.split(',').map((str) => str.trim());
                coordinates = {
                    lat,
                    lon,
                };
            } else {
                const projectionCode = fields[0].value;
                const x = +fields[1].value;
                const y = +fields[2].value;

                const lonLat = proj4(`EPSG:${projectionCode}`, 'EPSG:4326', [x, y]);

                coordinates = {
                    lat: lonLat[1],
                    lon: lonLat[0],
                };
            }

            this.$emit('location-searched', { coordinates });
        },

        /**
         * Get the validation rules string for the given field.
         *
         * @param {Object} field - field to get validation rules for
         * @returns {String} - string of the validation rules
         */
        getValidationRules(field) {
            const rules = 'required|number';

            if (field.min && field.max) {
                return `${rules}|between:${field.min},${field.max}`;
            }

            return rules;
        },
        /**
         * Query the API for a certain cabd_id.
         * This is defined in the API:
         * /api/features/search/index.js
         * @param {String} query The search string. Search doesn't happen unless the query is bigger than 2 chars.
         */
        searchById: debounce(async function fetchIds(query) {
            // minimum of length 2
            if (query.length <= 2) {
                this.idSearchResults = [];
                return;
            }
            // hidden layers will still return results here
            this.isFetching = true;
            const url = `${this.$config.CABD_API}features/${query.replace(' ', '')}`;
            // cancel any current requests that are occurring, this prevents a slow query from writing over a faster one
            // since slow queries are typically less characters than faster queries (i.e. 'Be' vs 'Beaver Lake')
            // we can assume a slow query will more likely overwrite the results than a faster query
            // in any case we want the most recent query to be the correct resultset.
            this.abortController.abort();
            try {
                const { data } = await axios.get(url, {
                    signal: this.abortController.signal,
                });

                this.idSearchResults = [data];
            } catch (error) {
                this.$handleError(error, this.$t('ERROR_LOCATION'));
            } finally {
                this.isFetching = false;
            }
        }, 200),
        /**
         * Query the API for a certain structure name.
         * This is defined in the API:
         * /api/features/search/index.js
         * @param {String} query The search string. Search doesn't happen unless the query is bigger than 2 chars.
         */
        searchByFacility: debounce(async function fetchFacilities(query) {
            // minimum of length 2
            if (query.length <= 2) {
                this.facilitySearchResults = [];
                return;
            }
            // hidden layers will still return results here
            this.isFetching = true;
            const url = `https://cabd-web.azurewebsites.net/cabd-api/features/dams/?filter=facility_name_${this.locale}:like:${query}`;
            // cancel any current requests that are occurring, this prevents a slow query from writing over a faster one
            // since slow queries are typically less characters than faster queries (i.e. 'Be' vs 'Beaver Lake')
            // we can assume a slow query will more likely overwrite the results than a faster query
            // in any case we want the most recent query to be the correct resultset.
            this.abortController.abort();
            try {
                const { data } = await axios.get(url, {
                    signal: this.abortController.signal,
                });

                this.facilitySearchResults = data.features;
            } catch (error) {
                this.$handleError(error, this.$t('ERROR_LOCATION'));
            } finally {
                this.isFetching = false;
            }
        }, 200),
        /**
         * Query the API for a certain structure name.
         * This is defined in the API:
         * /api/features/search/index.js
         * @param {String} query The search string. Search doesn't happen unless the query is bigger than 2 chars.
         */
        searchByWaterbody: debounce(async function fetchWaterbodies(query) {
            // minimum of length 2
            if (query.length <= 2) {
                this.waterbodySearchResults = [];
                return;
            }
            // hidden layers will still return results here
            this.isFetching = true;
            const url = `https://cabd-web.azurewebsites.net/cabd-api/features?filter=waterbody_name_${this.locale}:like:${query}`;
            // cancel any current requests that are occurring, this prevents a slow query from writing over a faster one
            // since slow queries are typically less characters than faster queries (i.e. 'Be' vs 'Beaver Lake')
            // we can assume a slow query will more likely overwrite the results than a faster query
            // in any case we want the most recent query to be the correct resultset.
            this.abortController.abort();
            try {
                const { data } = await axios.get(url, {
                    signal: this.abortController.signal,
                });

                this.waterbodySearchResults = data.features;
            } catch (error) {
                this.$handleError(error, this.$t('ERROR_LOCATION'));
            } finally {
                this.isFetching = false;
            }
        }, 200),
        /**
         * Query the API for a certain structure name.
         * This is defined in the API:
         * /api/features/search/index.js
         * @param {String} query The search string. Search doesn't happen unless the query is bigger than 2 chars.
         */
        searchByStructure: debounce(async function fetchStructures(query) {
            // minimum of length 2
            if (query.length <= 2) {
                this.structureSearchResults = [];
                return;
            }
            // hidden layers will still return results here
            this.isFetching = true;
            const url = `${this.$config.CABD_API}features?namefilter=like:${query}`;
            // cancel any current requests that are occurring, this prevents a slow query from writing over a faster one
            // since slow queries are typically less characters than faster queries (i.e. 'Be' vs 'Beaver Lake')
            // we can assume a slow query will more likely overwrite the results than a faster query
            // in any case we want the most recent query to be the correct resultset.
            this.abortController.abort();
            try {
                const { data } = await axios.get(url, {
                    signal: this.abortController.signal,
                });

                this.structureSearchResults = data.features;
            } catch (error) {
                this.$handleError(error, this.$t('ERROR_LOCATION'));
            } finally {
                this.isFetching = false;
            }
        }, 200),

        /**
         * query the mapbox search API and set autocomplete options
         * @param  {String} name search string
         */
        searchByName: debounce(async function fetchTitles(name) {
            if (name.length <= 2) {
                this.nameSearchResults = [];
                return;
            }
            this.isFetching = true;
            const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${name}.json?access_token=${process.env.VUE_APP_MAPBOX_TOKEN}&autocomplete=true&country=ca&types=address,place,region`;
            this.abortController.abort();

            try {
                const { data } = await axios.get(url, {
                    signal: this.abortController.signal,
                });

                this.nameSearchResults = data.features;
            } catch (error) {
                this.$handleError(error, this.$t('ERROR_LOCATION'));
            } finally {
                this.isFetching = false;
            }
        }, 200),

        /**
         * Select the option and emit a location-searched event
         *
         * @param {Object} option the option selected.
         */
        selectNameSearchOption(option) {
            if (!this.nameSearchResults.length) {
                // short circuit to so it doesn't search using a previous list
                // which seems to be a bug with b-autocomplete.
                return;
            }
            this.nameSearchResults = [];

            if (option) {
                if (option.bbox) {
                    const [minLng, minLat, maxLng, maxLat] = option.bbox;
                    this.$emit('location-searched', { boundingBox: [[minLng, minLat], [maxLng, maxLat]] });
                } else {
                    this.$emit('location-searched', { coordinates: option.center });
                }
            }
        },
        /**
         * Select the option and emit a location-searched event
         *
         * @param {Object} option the option selected.
         */
        selectStructureSearchOption(option) {
            if (!this.structureSearchResults.length) {
                // short circuit to so it doesn't search using a previous list
                // which seems to be a bug with b-autocomplete.
                return;
            }
            this.structureSearchResults = [];

            if (option?.geometry?.coordinates) {
                this.$emit('feature-select', option);
            }
        },
        /**
         * Select the option and emit a location-searched event
         *
         * @param {Object} option the option selected.
         */
        selectIdSearchOption(option) {
            if (!this.idSearchResults.length) {
                // short circuit to so it doesn't search using a previous list
                // which seems to be a bug with b-autocomplete.
                return;
            }
            this.structureSearchResults = [];

            if (option?.geometry?.coordinates) {
                this.$emit('feature-select', option);
            }
        },
        /**
         * Select the option and emit a location-searched event
         *
         * @param {Object} option the option selected.
         */
        selectFacilitySearchOption(option) {
            if (!this.facilitySearchResults.length) {
                // short circuit to so it doesn't search using a previous list
                // which seems to be a bug with b-autocomplete.
                return;
            }
            this.facilitySearchResults = [];

            if (option?.geometry?.coordinates) {
                this.$emit('feature-select', option);
            }
        },
        /**
         * Select the option and emit a location-searched event
         *
         * @param {Object} option the option selected.
         */
        selectWaterbodySearchOption(option) {
            if (!this.waterbodySearchResults.length) {
                // short circuit to so it doesn't search using a previous list
                // which seems to be a bug with b-autocomplete.
                return;
            }
            this.waterbodySearchResults = [];

            if (option?.geometry?.coordinates) {
                this.$emit('feature-select', option);
            }
        },
    },
};
</script>

<style lang="scss">
.search {
    background-color: $primary-light;

    .fields {
        .form-field {
            margin-bottom: 0;
            margin-right: 2px;

            .field-label {
                margin-right: 0.5rem;
                padding-top: 0.5rem;

                .label {
                    white-space: nowrap;
                    color: $white;
                    font-size: 1rem;
                    font-weight: 400;
                    text-align: left;
                    padding-left: 1rem;
                }
            }

            input {
                background-color: $primary-light;
                color: $white;
                outline: none;
                box-shadow: none;
                text-align: left;
                margin-top: 2px;
                margin-bottom: 2px;
                height: 36px;
                border: 2px solid #fff;

                &:focus-visible {
                    border-color: $element-focus;
                }

                &.is-danger {
                    height: 33px;
                }
            }

            .autocomplete .dropdown-menu {
                top: 102% !important;
                left: -31% !important;
                width: 127% !important;

                .dropdown-content {
                    padding: 0;

                    .dropdown-item {
                        padding-left: 0.5rem;
                        padding-right: 0.5rem;
                        height: fit-content;
                        white-space: pre-wrap;

                        .geocoder-search-result {
                            display: flex;
                            align-items: center;

                            .icon {
                                margin-right: 0.5rem;

                                &.barrier {
                                    color: $status-barrier;
                                }

                                &.unknown {
                                    color: $status-unknown;
                                }

                                &.undefined {
                                    color: $fishways;
                                }

                                &.passable {
                                    color: $status-passable;
                                }

                                &.partial_barrier {
                                    color: $status-partial;
                                }
                            }
                        }
                    }
                }
            }

            p.help {
                margin: 0;
            }
        }
    }

    .controls {
        display: grid;
        grid-template-columns: auto 1fr auto;
        padding: 0.25rem 0.5rem;

        .go-button {
            border: 2px solid transparent;

            &:focus-visible {
                outline: none;
                border-color: $element-focus;
            }
        }

        .coordinate-system-select {
            grid-column: 3/4;

            select {
                min-width: 8rem;
                border: 2px solid transparent;

                &:focus-visible {
                    outline: none;
                    border-color: $element-focus;
                }
            }
        }
    }
}
</style>
