import MapboxGlMap from '@/components/MapboxGlMap.vue';

export default {

    components: {
        MapboxGlMap,
    },

    methods: {
        /**
         * Return a set of default options for the map
         */
        getMapOptions(bounds = [[-50, 70], [-153, 42]]) {
            return {
                // enable style optimization, see: https://docs.mapbox.com/help/glossary/style-optimized-vector-tiles/
                style: 'mapbox://styles/foundryspatial/ckneygxd91mes17o7ff3z409i?optimize=true',
                bounds,
                fitBoundsOptions: {
                    padding: 35,
                },
                logoPosition: 'top-right',
            };
        },
        /**
         * pass mapbox-gl errors to global error handler to display a message
         * @param  {Object}   errorEvent  mapbox-gl error event
         */
        mapError(errorEvent) {
            // pass to error handler as a critical error
            this.$handleError(errorEvent, this.$t('API_CRITICAL_ERROR'), true);
        },

    },
};
