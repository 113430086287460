<template>
    <div
        role="listitem"
        :class="['layer-list-item', {'parent-hidden': parentHidden }]"
    >
        <div
            class="row"
            :role="hasChildren(layer) ? 'none' : 'switch'"
            tabindex="-1"
            :aria-checked="layer.active"
            :aria-label="
                hasChildren(layer)
                    ? ''
                    : ` ${layer.label} Layer`
            "
            @click="$emit('layer-toggled', layerIndexArray, !layer.active)"
        >
            <div
                :aria-checked="layer.active"
                :aria-label="
                    hasChildren(layer)
                        ? `${layer.label} Layer`
                        : ''
                "
                :tabindex="baseTabIndex + 1"
                class="layer-visible-icon"
                @keypress.enter="$emit('layer-toggled', layerIndexArray, !layer.active)"
            >
                <b-icon
                    pack="mdi"
                    size="is-small"
                    :icon="layer.active ? 'eye-outline' : 'eye-off-outline'"
                />
            </div>
            <div class="spacer" />
            <div
                v-if="layer.icon"
                class="layer-icon"
            >
                <b-icon
                    pack="mdi"
                    :icon="layer.icon"
                    class="symbol"
                />

                <!-- add outline icon and position it on top of colored icon -->
                <b-icon
                    pack="mdi"
                    :icon="`${layer.icon}-outline`"
                    :style="`color: ${accessibilityMode ? '#000' : '#fff'}`"
                    class="outline"
                />
            </div>
            <!-- if the icon is explicitly NULL, use an empty div for spacing -->
            <div
                v-if="layer.icon === null"
                class="layer-icon"
            />

            <div
                :aria-checked="layer.active"
                class="layer-label"
            >
                {{ $t(layer.label.toUpperCase().replaceAll(' ', '_')) }}
            </div>
        </div>
    </div>
</template>

<script>
import scssVars from '@/assets/scss/variables.module.scss';

export default {
    name: 'LayerListItem',

    props: {
        layer: {
            type: Object,
            required: true,
        },

        layerIndexArray: {
            type: Array,
            required: false,
            default: () => [],
        },

        baseTabIndex: {
            type: Number,
            required: true,
        },

        parentHidden: {
            type: Boolean,
            required: false,
            default: false,
        },

        accessibilityMode: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    methods: {
        hasChildren(layer) {
            return layer.children && layer.children.length;
        },
    },
};
</script>

<style lang="scss">
.layer-list-item {
    border-bottom: 1px solid $white;

    &:last-child {
        border-bottom: none;
    }

    &.parent-hidden {
        opacity: 0.5;
    }

    .row {
        display: grid;
        grid-template-columns: auto auto auto auto 1fr;

        .layer-visible-icon {
            border: 2px solid transparent;
            display: flex;
            align-items: center;
            padding: 0.15rem 0.5rem 0 0.5rem;

            &:focus-visible {
                outline: none;
                border-color: $element-focus;
            }
        }

        .parent-toggle-container {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .parent-toggle {
                width: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0.5rem;

                &:focus-visible {
                    outline: none;
                }
            }

            .show-parent-toggle-focus {
                position: absolute;
                top: 0;
                left: -25%;
                height: 100%;
                width: 120%;
            }

            .parent-toggle:focus + .show-parent-toggle-focus {
                border: 3px solid $element-focus;
            }
        }

        .spacer {
            width: 0.15rem;
            margin-right: 0.5rem;
            border-left: 1px solid $white;
        }

        .layer-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 0.5rem 0 0;
            position: relative;

            .icon.outline {
                position: absolute;
                top: 0.2rem;
                left: 0;
            }

            .symbology {
                position: absolute;
                color: #000;

                &.square {
                    &.partial-barrier {
                        font-size: 1.3rem;
                        top: -1px;
                        left: 11px;
                        transform: rotate(45deg);
                    }

                    &.barrier {
                        top: -2px;
                        font-size: 1.25rem;
                    }

                    &.unknown {
                        font-size: 0.8rem;
                    }
                }

                &.triangle {
                    &.partial-barrier {
                        font-size: 1rem;
                        top: 7px;
                        left: 10px;
                        transform: rotate(60deg);
                        font-weight: 700;
                    }

                    &.barrier {
                        top: 5px;
                    }

                    &.unknown {
                        top: 12px;
                        font-size: 0.6rem;
                    }
                }
            }
        }

        .layer-label {
            padding: 0.25rem 0;
            user-select: none;
        }
    }

    .child-layers {
        border-top: 1px solid $white;
    }

    .child-layer {
        .row {
            .layer-icon {
                margin-left: 1.5rem;
            }
        }
    }
}
</style>
