<template>
    <div class="watershed-display box pt-1 pb-1">
        <p v-if="watershed">
            {{ watershed }}
        </p>
        <p v-else>
            {{ $t('HOVER_TEXT') }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'WatershedName',

    props: {
        watershed: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.watershed-display {
    position: absolute;
    font-size: 0.9em;
    top: 76px;
    right: 12px + 10px;
}
</style>
