<template>
    <div :class="['sidebar', sidebarExpanded ? 'expanded' : 'closed']">
        <div :class="['sidebar-wrapper', sidebarExpanded ? 'expanded' : 'closed']">
            <h1 class="page-title">
                {{ $t('CANADIAN_AQUATIC_BARRIERS_DATABASE') }}
            </h1>
            <div class="language-accessiblity">
                <!-- Leaving this commented out in case we want to switch back, and I hope we switch back -->
                <!-- <b-button @click="switchLanguage()">
                    {{ $t('SWITCH_LANGUAGE') }}
                </b-button> -->
                <div
                    role="switch"
                    tabindex="298"
                    class="accessibility-mode-row"
                    @keypress.enter="switchLanguage"
                >
                    <b-switch
                        v-model="languageIsEnglish"
                        size="is-small"
                    >
                        {{ $t('OTHER_LANGUAGE') }}
                    </b-switch>
                </div>
                <div
                    role="switch"
                    tabindex="299"
                    aria-label="Accessibility Mode"
                    :aria-checked="accessibilityModeActive"
                    class="accessibility-mode-row"
                    @keypress.enter="accessibilityModeActive = !accessibilityModeActive"
                >
                    <b-switch
                        v-model="accessibilityModeActive"
                        size="is-small"
                    >
                        {{ $t('ACCESSIBILITY_MODE') }}
                    </b-switch>
                </div>
                <hr>
            </div>
            <div class="sidebar-filters">
                <div
                    v-if="viewingMap"
                    role="button"
                    class="sidebar-toggle-row row"
                    tabindex="1"
                    @keypress.enter="toggleSidebar()"
                    @click="toggleSidebar()"
                >
                    <b-tooltip
                        v-if="!sidebarExpanded"
                        label="Show Menu"
                        position="is-right"
                        class="menu-closed sidebar-toggle"
                    >
                        <b-icon
                            pack="mdi"
                            icon="chevron-double-right"
                            aria-label="Open menu"
                        />
                    </b-tooltip>
                    <b-icon
                        v-else
                        pack="mdi"
                        icon="chevron-double-left"
                        aria-label="Close Menu"
                        class="menu-open sidebar-toggle"
                    />
                    <div class="show-icon-focus" />
                </div>
                <hr v-if="viewingMap">
                <b-collapse
                    v-if="viewingMap"
                    :open.sync="expanded.search"
                    :animation="null"
                    role="search"
                    @open="sidebarExpanded = true"
                >
                    <template #trigger>
                        <div
                            role="button"
                            tabindex="100"
                            :aria-label="`${expanded.search ? 'Close' : 'Open'} search drawer`"
                            class="collapse-trigger-content"
                            @keypress.enter="toggleSection('search')"
                        >
                            <b-tooltip
                                v-if="!sidebarExpanded"
                                label="Search"
                                position="is-right"
                                size="is-small"
                                class="sidebar-tooltip"
                            >
                                <b-icon
                                    pack="mdi"
                                    icon="magnify"
                                />
                            </b-tooltip>
                            <b-icon
                                v-else
                                pack="mdi"
                                icon="magnify"
                            />
                            <div v-if="sidebarExpanded">
                                {{ $t('SEARCH') }}
                            </div>
                            <b-icon
                                v-if="sidebarExpanded"
                                pack="mdi"
                                :icon="expanded.search ? 'chevron-up' : 'chevron-down'"
                            />
                        </div>
                    </template>
                    <div class="collapse-body-content">
                        <search-form
                            :attribute-filters="attributeFilters"
                            @location-searched="(coordinates) => $emit('location-searched', coordinates)"
                            @feature-select="(feature) => $emit('feature-select', feature)"
                        />
                    </div>
                </b-collapse>
                <hr v-if="viewingMap">
                <b-collapse
                    v-if="viewingMap"
                    :open.sync="expanded.layers"
                    :animation="null"
                    role="form"
                    aria-label="Layer List"
                    @open="sidebarExpanded = true"
                >
                    <template #trigger>
                        <div
                            role="button"
                            tabindex="200"
                            :aria-label="`${expanded.layers ? 'Close' : 'Open'} layers drawer`"
                            class="collapse-trigger-content"
                            @keypress.enter="toggleSection('layers')"
                        >
                            <b-tooltip
                                v-if="!sidebarExpanded"
                                label="Layers"
                                position="is-right"
                                size="is-small"
                                class="sidebar-tooltip"
                            >
                                <b-icon
                                    pack="mdi"
                                    icon="layers"
                                />
                            </b-tooltip>
                            <b-icon
                                v-else
                                pack="mdi"
                                icon="layers"
                            />
                            <div v-if="sidebarExpanded">
                                {{ $t('LAYERS') }}
                            </div>
                            <b-icon
                                v-if="sidebarExpanded"
                                pack="mdi"
                                :icon="expanded.layers ? 'chevron-up' : 'chevron-down'"
                            />
                        </div>
                    </template>
                    <div class="collapse-body-content">
                        <layer-list
                            :attribute-filters="attributeFilters"
                            :accessibility-mode-active="accessibilityModeActive"
                            @layers-updated="(layersInfo) => $emit('layers-updated', layersInfo, null, false)"
                            @accessibility-mode-toggled="
                                (accessibilityModeOn) => $emit('accessibility-mode-toggled', accessibilityModeOn)
                            "
                        />
                    </div>
                </b-collapse>
                <hr v-if="viewingMap">
                <b-collapse>
                    <template #trigger>
                        <div
                            tabindex="300"
                            role="button"
                            aria-label="Open Filters popup"
                            class="collapse-trigger-content open-filters"
                            @keypress.enter="$emit('open-filters')"
                            @click="openSidebar('open-filters')"
                        >
                            <b-tooltip
                                v-if="!sidebarExpanded"
                                label="Contact Us"
                                position="is-right"
                                class="sidebar-tooltip"
                            >
                                <b-icon
                                    pack="mdi"
                                    icon="filter-outline"
                                />
                            </b-tooltip>
                            <b-icon
                                v-else
                                pack="mdi"
                                icon="filter-outline"
                            />
                            <div v-if="sidebarExpanded">
                                {{ $t('FILTER_DATA') }}
                            </div>
                        </div>
                    </template>
                </b-collapse>
                <hr v-if="viewingMap">
                <b-collapse>
                    <template #trigger>
                        <div
                            tabindex="302"
                            role="button"
                            aria-label="Open Downloads popup"
                            class="collapse-trigger-content open-downloads"
                            @keypress.enter="$emit('open-downloads')"
                            @click="openSidebar('open-downloads')"
                        >
                            <b-tooltip
                                v-if="!sidebarExpanded"
                                label="Contact Us"
                                position="is-right"
                                class="sidebar-tooltip"
                            >
                                <b-icon
                                    pack="mdi"
                                    icon="download"
                                />
                            </b-tooltip>
                            <b-icon
                                v-else
                                pack="mdi"
                                icon="download"
                            />
                            <div v-if="sidebarExpanded">
                                {{ $t('DOWNLOAD_DATA') }}
                            </div>
                        </div>
                    </template>
                </b-collapse>
                <hr v-if="viewingMap">
                <div role="navigation">
                    <b-collapse v-if="viewingMap">
                        <template #trigger>
                            <a
                                href="https://cabd-docs.netlify.app/docs_user/docs_user_data_sources.html"
                                target="_blank"
                                tabindex="400"
                                class="collapse-trigger-content link"
                            >
                                <b-tooltip
                                    v-if="!sidebarExpanded"
                                    label="Data Sources"
                                    position="is-right"
                                    class="sidebar-tooltip"
                                >
                                    <b-icon
                                        pack="mdi"
                                        icon="information"
                                    />
                                </b-tooltip>
                                <b-icon
                                    v-else
                                    pack="mdi"
                                    icon="information"
                                />
                                <div v-if="sidebarExpanded">
                                    {{ $t('DATA_SOURCES') }}
                                </div>
                            </a>
                        </template>
                    </b-collapse>
                    <b-collapse v-if="viewingDataSources">
                        <template #trigger>
                            <a
                                href="/"
                                tabindex="400"
                                class="collapse-trigger-content link"
                            >
                                <b-icon
                                    pack="mdi"
                                    icon="map"
                                />
                                {{ $t('EXPLORE_DATA') }}
                            </a>
                        </template>
                    </b-collapse>
                    <hr>
                    <b-collapse>
                        <template #trigger>
                            <a
                                target="_blank"
                                href="https://cabd-docs.netlify.app/"
                                tabindex="450"
                                class="collapse-trigger-content link"
                            >
                                <b-tooltip
                                    v-if="!sidebarExpanded"
                                    label="Documentation Site"
                                    position="is-right"
                                    class="sidebar-tooltip"
                                >
                                    <b-icon
                                        pack="mdi"
                                        icon="text-box-multiple"
                                    />
                                </b-tooltip>
                                <b-icon
                                    v-else
                                    pack="mdi"
                                    icon="text-box-multiple"
                                />
                                <div v-if="sidebarExpanded">
                                    {{ $t('DOCUMENTATION_SITE') }}
                                </div>
                            </a>
                        </template>
                    </b-collapse>
                    <hr v-if="sidebarExpanded">
                    <b-collapse>
                        <template #trigger>
                            <a
                                target="_blank"
                                href="mailto:cabd@cwf-fcf.org"
                                tabindex="500"
                                class="collapse-trigger-content link"
                            >
                                <b-tooltip
                                    v-if="!sidebarExpanded"
                                    label="Contact Us"
                                    position="is-right"
                                    class="sidebar-tooltip"
                                >
                                    <b-icon
                                        pack="mdi"
                                        icon="email"
                                    />
                                </b-tooltip>
                                <b-icon
                                    v-else
                                    pack="mdi"
                                    icon="email"
                                />
                                <div v-if="sidebarExpanded">
                                    {{ $t('CONTACT_US') }}
                                </div>
                            </a>
                        </template>
                    </b-collapse>
                    <hr v-if="sidebarExpanded">
                    <p
                        v-if="sidebarExpanded"
                        class="sidebar-footer"
                    >
                        {{ $t('ABOUT') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LayerList from '@/components/LayerList.vue';
import SearchForm from '@/components/SearchForm.vue';
// import { Router } from 'express';
// import { useRouter } from 'vue-router';

export default {
    name: 'Sidebar',

    components: {
        LayerList,
        SearchForm,
    },

    props: {
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },

        attributeFilters: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        sidebarExpanded: true,
        expanded: {
            search: false,
            layers: false,
        },
        languageIsEnglish: false,
        accessibilityModeActive: false,
    }),

    computed: {
        sidebarView() {
            if (this.$route.path === '/data-sources') {
                return 'data-sources';
            }
            return 'map-view';
        },

        viewingMap() {
            return this.$route.path !== '/data-sources';
        },

        viewingDataSources() {
            return this.$route.path === '/data-sources';
        },
    },

    watch: {
        accessibilityModeActive(newValue) {
            this.$emit('accessibility-mode-toggled', newValue);
        },
        languageIsEnglish() {
            this.switchLanguage();
        },
    },

    methods: {
        /**
         * Toggles (hides) the sidebar when the user clicks on the icon
         */
        toggleSidebar() {
            this.sidebarExpanded = !this.sidebarExpanded;

            if (!this.sidebarExpanded) {
                Object.keys(this.expanded).forEach((key) => {
                    this.expanded[key] = false;
                });
                this.$emit('close');
            }
        },

        openSidebar(sidebar) {
            this.sidebarExpanded = true;
            this.$emit(sidebar);
        },

        toggleSection(section) {
            this.expanded[section] = !this.expanded[section];
            if (this.expanded[section]) {
                this.sidebarExpanded = true;
            }
        },

        switchLanguage() {
            if (this.$i18n.locale === 'en') {
                this.$router.push('/fr');
                this.$router.go();
            } else {
                this.$router.push('/en');
                this.$router.go();
            }
        },
    },
};
</script>

<style lang="scss">
.sidebar {
    $row-border: 2px solid transparent;
    $row-border-width: 2px 3px;
    $row-padding: 0.35rem 0.25rem;

    width: 15rem;
    color: $white;
    z-index: 40;

    hr {
        margin: 0;
        height: 1px;
    }

    .sidebar-wrapper {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr 13.5em;
        grid-template-areas:
            'title'
            'language'
            'filters'
            'logo';
        &.closed {
            grid-template-rows: auto auto 1fr 5em;
        }
        @media screen and (max-height: 625px) {
            grid-template-rows: auto auto 1fr 11.5em;
        }
    }

    .sidebar-filters {
        grid-area: filters;
        padding-bottom: 1em;
        overflow-y: auto;
        overflow-x: hidden;
        width: 2rem;
    }

    // area the absolute logo will appear in
    .logo-wrapper {
        grid-area: logo;
    }

    a.link {
        color: white;
    }

    .page-title {
        grid-area: title;
        background-color: $sidebar-header-background;
        text-transform: uppercase;
        font-family: 'Raleway', sans-serif;
        font-size: 1.25rem;
        padding: 1rem;
        text-align: center;
    }

    .language-accessiblity {
        grid-area: language;
        background-color: $sidebar-background-dark;
        text-align: center;

        button {
            background-color: $primary-light;
            color: $white;
            cursor: pointer;
            margin-top: 0.25em;
        }

        .accessibility-mode-row {
            display: flex;
            align-items: center;
            padding: 0.3rem 0.25rem;

            &:focus-visible {
                border: none;
                outline: none;

                .switch .check {
                    outline: 2px solid $element-focus;
                }
            }

            .switch {
                input[type=checkbox] {
                    + .check {
                        background-color: $grey-light;

                        &::before {
                            margin-top: 1px;
                            margin-left: 1px;
                        }

                        &.is-elastic::before {
                            transform: none;
                        }
                    }

                    &:checked + .check {
                        background-color: $primary-light;

                        &::before {
                            transform: translate3d(calc(100% - 1px), 0, 0);
                        }
                    }
                }

                .control-label {
                    font-size: 1rem;
                    padding-left: 0.6rem;
                    padding-right: 0.25rem;
                }
            }

            button {
                background-color: $primary-light;
                color: white;
                cursor: pointer;
                font-weight: bold;
            }
        }
    }

    .sidebar-toggle-row {
        $sidebar-toggle-row-height: 2rem;
        $sidebar-toggle-icon-width: 2rem;

        position: relative;
        height: $sidebar-toggle-row-height;
        width: $sidebar-toggle-icon-width;
        background-color: $sidebar-background;
        display: grid;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .b-tooltip,
        .tooltip-trigger,
        .icon {
            height: $sidebar-toggle-row-height;
            width: $sidebar-toggle-icon-width;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .show-icon-focus {
            position: absolute;
            top: 0;
            right: 0;
            height: $sidebar-toggle-row-height;
            width: $sidebar-toggle-icon-width;
            border: 2px solid transparent;
        }

        &:focus-visible {
            outline: none;

            .show-icon-focus {
                border-color: $element-focus;
            }
        }
    }

    .collapse {
        .collapse-trigger-content {
            background-color: $sidebar-background-dark;
            padding: $row-padding;
            border: $row-border;
            border-width: $row-border-width;
            display: grid;
            justify-content: center;
            width: 100%;

            &:focus-visible {
                border-color: $element-focus;
                outline: none;
                outline-offset: 0;
            }

            .sidebar-tooltip {
                height: 1.5em;
            }
        }
    }

    // click through the sidebar wrapper element when it's collapsed
    &.closed {
        // only handle clicks on the green buttons
        .sidebar-filters > * {
            pointer-events: auto;
        }
    }

    &.expanded {
        height: 100%;
        background-color: $sidebar-background;

        .sidebar-filters {
            width: 100%;

            .sidebar-toggle-row {
                width: 100%;
                grid-template-columns: 1fr auto;

                .sidebar-toggle {
                    padding-right: 0.3rem;
                    padding-left: 0.2rem;
                    grid-column: 2/3;
                }
            }

            .collapse {
                width: 100%;

                .collapse-trigger-content {
                    grid-template-columns: auto 1fr auto;
                    column-gap: 1rem;
                }
            }
        }
    }

    .sidebar-footer {
        bottom: 3.6rem;
        font-size: 9pt;
        padding: 2em 2em 2em 3em;
        position: absolute;

        @media screen and (max-height: 625px) {
            font-size: 8pt;
        }
    }
}
</style>
