<template>
    <b-sidebar
        v-model="showSidebar"
        type="is-light"
        fullheight
        :can-cancel="false"
        class="filters-sidebar"
    >
        <b-icon
            icon="chevron-double-left"
            class="close-sidebar"
            @click.native="closeSidebar"
        />
        <h2 class="filters-header subtitle is-3 has-text-centered">
            {{ $t('FILTERS') }}
        </h2>
        <div class="filters-container">
            <div
                v-if="attributeFiltersDefined"
                class="filters"
            >
                <location-filters
                    :location-filters="locationFiltersLocal"
                    :feature-type-definitions="featureTypeDefinitions"
                    @updated="(newFilters) => toggleLocationType(newFilters)"
                    @nhn="() => $emit('nhn')"
                />
                <attribute-filters
                    feature-type="dams"
                    :feature-type-label="$t('DAMS')"
                    :filter="attributeFiltersLocal.dams"
                    :starting-tab-index="750"
                    :feature-count="featureCount.dams"
                    :total-count="totalCount.dams"
                    class="dam-filters filter-section"
                    @updated="(newFilters) => updateFilters('dams', newFilters)"
                />
                <attribute-filters
                    feature-type="waterfalls"
                    :feature-type-label="$t('WATERFALLS')"
                    :filter="attributeFiltersLocal.waterfalls"
                    :starting-tab-index="800"
                    :feature-count="featureCount.waterfalls"
                    :total-count="totalCount.waterfalls"
                    class="waterfall-filters filter-section"
                    @updated="(newFilters) => updateFilters('waterfalls', newFilters)"
                />
                <attribute-filters
                    feature-type="fishways"
                    :feature-type-label="$t('FISHWAYS')"
                    :filter="attributeFiltersLocal.fishways"
                    :starting-tab-index="850"
                    :feature-count="featureCount.fishways"
                    :total-count="totalCount.fishways"
                    class="fishway-filters filter-section"
                    @updated="(newFilters) => updateFilters('fishways', newFilters)"
                />
                <!-- <attribute-filters
                    feature-type="flowpaths"
                    :feature-type-label="$t('HYDRO_NETWORKS')"
                    :filter="attributeFiltersLocal['flowpaths']"
                    :starting-tab-index="900"
                    class="hydro-network-filters filter-section"
                    @updated="(newFilters) => updateFilters('flowpaths', newFilters)"
                />
                <attribute-filters
                    feature-type="catchments"
                    :feature-type-label="$t('CATCHMENTS')"
                    :filter="attributeFiltersLocal['catchments']"
                    :starting-tab-index="950"
                    class="hydro-network-filters filter-section"
                    @updated="(newFilters) => updateFilters('catchments', newFilters)"
                /> -->
                <attribute-filters
                    feature-type="nhn-work-units"
                    :feature-type-label="$t('NHN_WATERSHED_BOUNDARIES')"
                    :filter="attributeFiltersLocal['nhn-work-units']"
                    :starting-tab-index="1000"
                    class="nhn-work-units-filters filter-section"
                    @updated="(newFilters) => updateFilters('nhn-work-units', newFilters)"
                />
            </div>
            <b-loading
                v-else
                active
                :is-full-page="false"
            />
        </div>
        <div class="buttons-container">
            <b-button
                type="is-primary"
                :tabindex="1000"
                @click="applyFilters"
            >
                {{ $t('APPLY_FILTERS') }}
            </b-button>
            <b-button
                type="is-light"
                :tabindex="1001"
                @click="resetFilters"
            >
                {{ $t('CLEAR_FILTERS') }}
            </b-button>
        </div>
        <b-button
            type="is-primary"
            :tabindex="1002"
            class="download-button"
            @click="downloadData"
        >
            {{ $t('DOWNLOAD_DATA') }}
        </b-button>
    </b-sidebar>
</template>

<script>
import isEmpty from 'lodash.isempty';

import AttributeFilters from '@/components/filters/AttributeFilters.vue';
import LocationFilters from '@/components/filters/LocationFilters.vue';

export default {
    name: 'FilterSidebar',

    components: {
        LocationFilters,
        AttributeFilters,
    },

    props: {
        locationFilters: {
            type: Object,
            required: true,
        },

        attributeFilters: {
            type: Object,
            required: true,
        },

        featureTypeDefinitions: {
            type: Object,
            required: true,
        },

        defaultAttributeFilters: {
            type: Object,
            required: true,
        },

        featureCount: {
            type: Object,
            required: true,
        },

        totalCount: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        locationFiltersLocal: {
            locationType: 'province',
            regions: [],
            selectedProvinces: [],
            selectedWatersheds: [],
        },
        attributeFiltersLocal: {},
        showSidebar: false,
        unappliedFilters: false,
        lastRegion: [],
    }),

    computed: {
        attributeFiltersDefined() {
            return !isEmpty(this.attributeFiltersLocal);
        },
    },

    watch: {
        attributeFilters: {
            deep: true,
            handler: function handler() {
                this.attributeFiltersLocal = JSON.parse(JSON.stringify(this.attributeFilters));
            },
        },
        locationFilters: {
            deep: true,
            handler: function handler() {
                this.locationFiltersLocal = JSON.parse(JSON.stringify(this.locationFilters));
            },
        },
    },

    mounted() {
        this.attributeFiltersLocal = JSON.parse(JSON.stringify(this.attributeFilters));
        this.locationFiltersLocal = JSON.parse(JSON.stringify(this.locationFilters));
    },

    methods: {
        /**
         * Toggle showing the sidebar
         */
        toggleSidebar() {
            this.showSidebar = !this.showSidebar;
        },

        closeSidebar() {
            if (this.showSidebar) {
                this.showSidebar = false;
                this.$emit('close');
            }
        },

        sidebarOpened() {
            return this.showSidebar;
        },

        /**
         * Update the filters for the given filter type.
         *
         * @param {string} type - filter type to update
         * @param {object} newFilters - new filters to use
         */
        updateFilters(type, newFilters) {
            this.unappliedFilters = true;
            this.$set(this.attributeFiltersLocal, type, newFilters);
        },

        /**
         * Reset all filters to their default values.
         */
        resetFilters() {
            this.attributeFiltersLocal = JSON.parse(JSON.stringify(this.defaultAttributeFilters));
            this.locationFiltersLocal = {
                locationType: this.locationFiltersLocal.locationType,
                regions: [],
                selectedProvinces: [],
                selectedWatersheds: [],
            };
            this.unappliedFilters = false;
            this.$emit('apply-filters', this.attributeFiltersLocal, this.locationFiltersLocal, false);
        },

        /**
         * Emit the 'apply-filters' event.
         */
        applyFilters() {
            this.unappliedFilters = false;
            let pan = false;
            if (this.lastRegion.length !== this.locationFiltersLocal.regions.length) {
                pan = true;
            } else {
                pan = !this.locationFiltersLocal.regions.every((val, i) => val.name === this.lastRegion[i].name);
            }
            this.lastRegion = this.locationFiltersLocal.regions;
            this.$emit('apply-filters', this.attributeFiltersLocal, this.locationFiltersLocal, pan);
        },

        /**
         * Apply filters when switching between Province/Watersheds
         * @param {object} newFilters // new filters to be applied
         */
        toggleLocationType(newFilters) {
            if (newFilters.locationType !== this.locationFiltersLocal.locationType) {
                this.locationFiltersLocal = newFilters;
            } else {
                this.locationFiltersLocal = newFilters;
            }
        },

        /**
         * Closes this dialog and opens the "Download data" dialog.
         */
        downloadData() {
            if (this.unappliedFilters) {
                this.unappliedFilters = false;
                this.$emit('apply-filters', this.attributeFiltersLocal, this.locationFiltersLocal);
            }
            this.$emit('download-data');
        },
    },
};
</script>

<style lang="scss">
.close-sidebar {
    cursor: pointer;
    position: absolute;
    right: 1em;
}
.filters-sidebar.b-sidebar > .sidebar-content {
    left: 15rem;
    width: 24rem;
    padding: 1rem;
}
.filters-container {
    position: relative;
    overflow-y: hidden;

    .filters {
        height: 100%;
        display: grid;
        grid-auto-flow: row;
        grid-template-rows: repeat(4, auto);
        align-content: start;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 0rem 0;

        .location-filters {
            margin-bottom: 2rem;
        }

        .filter-section {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.filters-header {
    border-bottom: 1px solid $grey-light;
    padding: 0.5rem 0;
    margin-bottom: 0;
}

.filters-sidebar {
    .buttons-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 1em;
    }
    .download-button {
        margin-left: auto;
        margin-right: auto;
    }
}
</style>
