<template>
    <div :class="['attribute-filters', {'feature-type-excluded': !filterLocal.active}]">
        <b-collapse
            v-model="filterExpanded"
            role="form"
            @open="toggleCollapse"
            @close="toggleCollapse"
        >
            <template #trigger>
                <div
                    :class="['trigger-container', { 'is-not-clickable': !hasFilters }, { 'count': featureCount > -1}]"
                    @click.stop="toggleCollapse"
                >
                    <b-switch
                        v-model="filterLocal.active"
                        size="is-small"
                        :tabindex="startingTabIndex"
                        :class="`${featureType}-active-switch`"
                        role="switch"
                        :aria-label="`${featureTypeLabel} Toggle`"
                        @click.native.stop
                        @input="handleSwitchInput()"
                        @keypress.enter.native="handleSwitchInput()"
                    />
                    <h3 class="subtitle is-4">
                        {{ featureTypeLabel }}
                    </h3>
                    <p
                        v-if="featureCount > -1"
                        class="feature-count"
                    >
                        {{ featureCount }} / {{ totalCount }}
                    </p>
                    <div
                        v-if="hasFilters"
                        class="toggle-container"
                    >
                        <b-icon
                            pack="mdi"
                            size="is-medium"
                            role="button"
                            :aria-label="`Expand or collapse the ${featureType} filter options`"
                            :tabindex="startingTabIndex + 1"
                            :icon="filterExpanded ? 'chevron-up' : 'chevron-down'"
                            class="toggle"
                            @keypress.enter.native="toggleCollapse"
                        />
                        <div class="show-toggle-focus" />
                    </div>
                </div>
            </template>
            <div
                v-for="(subfilter, filterIndex) in filterLocal.subfilters"
                :key="subfilter.header"
                class="subfilter"
            >
                <h4 class="title is-5">
                    {{ subfilter.header }}
                    <span
                        v-if="subfilter.type === 'checkbox'"
                        class="select-all-checkbox"
                    >
                        <b-checkbox
                            :key="`${subfilter.header}-all`"
                            :value="subfilter.values.every((v) => v.active)"
                            :indeterminate="!subfilter.values.every((v) => subfilter.values[0].active === v.active)"
                            :tabindex="tabIndex(filterIndex)"
                            @input="(value) => subfilter.values.forEach((v) => v.active = value)"
                        />
                    </span>
                </h4>
                <div
                    v-if="subfilter.type === 'checkbox' || subfilter.type === 'boolean'"
                    :class="['checkboxes', { 'boolean': subfilter.type === 'boolean' }]"
                >
                    <b-checkbox
                        v-for="(value, valueIndex) in subfilter.values"
                        :key="value.name"
                        v-model="value.active"
                        :tabindex="tabIndex(filterIndex)"
                        :class="`${featureType}-${filterIndex}-${valueIndex}`"
                        @input="handleCheckboxInput(`${featureType}-${filterIndex}-${valueIndex}`)"
                    >
                        <span v-if="subfilter.type === 'boolean'">{{ formatSubfilter(value.name) }}</span>
                        <span v-if="subfilter.type === 'checkbox'">{{ value.name }}</span>
                    </b-checkbox>
                </div>
                <div
                    v-if="subfilter.type === 'range'"
                    class="range"
                >
                    <div
                        role="figure"
                        aria-label="Minimum value range inputs can be set to"
                        class="min-value"
                    >
                        {{ subfilter.range[0] }}
                    </div>
                    <b-slider
                        v-model="subfilter.filteredRange"
                        :min="subfilter.range[0]"
                        :max="subfilter.range[1]"
                        :tooltip="false"
                        :aria-hidden="true"
                        @change="emitUpdatedFilters"
                    />
                    <div
                        role="figure"
                        aria-label="Maximum value range inputs can be set to"
                        class="max-value"
                    >
                        {{ subfilter.range[1] }}
                    </div>
                    <b-field
                        class="min-input"
                    >
                        <b-numberinput
                            v-model="subfilter.filteredRange[0]"
                            :controls="false"
                            :min="subfilter.range[0]"
                            :max="subfilter.range[1]"
                            aria-label="Minimum value to filter for"
                            :tabindex="tabIndex(filterIndex)"
                            @input="emitUpdatedFilters"
                        />
                    </b-field>
                    <span class="hyphen">
                        -
                    </span>
                    <b-field
                        class="max-input"
                    >
                        <b-numberinput
                            v-model="subfilter.filteredRange[1]"
                            :controls="false"
                            :min="subfilter.range[0]"
                            :max="subfilter.range[1]"
                            aria-label="Maximum value to filter for"
                            :tabindex="tabIndex(filterIndex)"
                            @input="emitUpdatedFilters"
                        />
                    </b-field>
                </div>
            </div>
        </b-collapse>
    </div>
</template>

<script>
export default {
    name: 'AttributeFilters',

    props: {
        featureType: {
            type: String,
            required: true,
        },

        featureTypeLabel: {
            type: String,
            required: true,
        },

        filter: {
            type: Object,
            required: true,
        },

        startingTabIndex: {
            type: Number,
            required: true,
        },

        featureCount: {
            type: Number,
            default: -1,
        },

        totalCount: {
            type: Number,
            default: -1,
        },
    },

    data: () => ({
        filterExpanded: false,
        filterLocal: {},
    }),

    computed: {
        hasFilters() {
            return !!(this.filter.subfilters && this.filter.subfilters.length);
        },
    },

    watch: {
        filter() {
            this.filterLocal = { ...this.filter };
        },
    },

    mounted() {
        this.filterLocal = { ...this.filter };
    },

    methods: {
        /**
         * Emit the 'updated' event with the current filters attached.
         */
        emitUpdatedFilters() {
            this.$emit('updated', this.filterLocal);
        },

        /**
         * Get the tab index to use based on the given filterIndex.
         *
         * @param {number} filterIndex - index to base tab index on
         * @returns {number} - calculated tab index
         */
        tabIndex(filterIndex) {
            // final 3 is to account for the switch and expand toggle at beginning of group
            return this.startingTabIndex + filterIndex + 3;
        },

        /**
         * Handle a switch being updated. This will focus a switch based on the current feature type and emit the
         * updated filters.
         *
         * This is to account for the fact that clicking on the switch behaves differently than tabbing to it and
         * pressing enter. A different element is actually focussed, and so it no longer follows the set tabindex
         * order.
         */
        handleSwitchInput() {
            this.emitUpdatedFilters();

            const switchInput = document.querySelector(`label.switch.${this.featureType}-active-switch`);

            setTimeout(() => {
                switchInput.focus();
            }, 50);
        },

        /**
         * Handle a checkbox being updated. This will focus a checkbox based on the given class and emit the updated
         * filters.
         *
         * This is to account for the fact that clicking on the checkbox behaves differently than tabbing to it and
         * pressing enter. A different element is actually focussed, and so it no longer follows the set tabindex
         * order.
         *
         * @param {string} checkboxClass - the class of the checkbox we want to focus.
         */
        handleCheckboxInput(checkboxClass) {
            const checkbox = document.querySelector(`label.b-checkbox.checkbox.${checkboxClass}`);
            this.emitUpdatedFilters();
            setTimeout(() => {
                checkbox.focus();
            }, 50);
        },

        toggleCollapse() {
            if (this.hasFilters) {
                this.filterExpanded = !this.filterExpanded;
            } else {
                this.filterExpanded = false;
            }
        },

        /**
         * Format subfilter to match messages.js
         * @param {String} subFilter
         */
        formatSubfilter(subFilter) {
            return this.$t(subFilter.replace(/ |\/|-/gi, '_').toUpperCase());
        },
    },
};
</script>

<style lang="scss">
.attribute-filters {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    .select-all-checkbox {
        margin-left: 0.4em;
        font-size: 1rem;
        line-height: 1rem;
        vertical-align: bottom;

        .b-checkbox.checkbox.is-small {
            font-size: 1em;
        }
    }

    .collapse-trigger .trigger-container {
        display: grid;
        grid-template-columns: repeat(2, auto) 1fr auto;
        &.count {
            grid-template-columns: auto 1fr 0.75fr auto;
        }
        align-items: center;
        column-gap: 0.25rem;
        width: 98%;

        &.is-not-clickable {
            cursor: default !important;
        }

        .switch {
            border: none;
            outline: none;

            &:focus-visible {
                .check {
                    outline: 2px solid $element-focus;
                }
            }

            input:focus-visible + .check {
                outline: 2px solid $element-focus;
            }
        }

        .icon {
            color: $primary;
        }

        .subtitle {
            margin-bottom: 0;
            margin-left: 1rem;
        }

        .toggle-container {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: right;
        }

        .toggle {
            width: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0.5rem;

            &:focus-visible {
                outline: none;
            }
        }

        .toggle:focus {
            width: 2rem;
            border: 3px solid $element-focus;
        }

        .feature-count {
            border: 2px solid grey;
            border-radius: 0.3em;
            text-align: center;
            font-size: 0.9em;
        }
    }

    .collapse-content {
        padding: 1em 1em;
        display: grid;
        row-gap: 1rem;

        .select-all-checkbox {
            // stylelint-disable-next-line no-descending-specificity
            .checkbox:focus .check {
                outline: 2px solid $element-focus;
            }

            // stylelint-disable-next-line no-descending-specificity
            input:focus-visible + .check {
                outline: 2px solid $element-focus;
            }
        }

        .checkboxes {
            display: grid;
            gap: 1rem;

            &.boolean {
                grid-template-columns: repeat(2, auto) 1fr;
                column-gap: 0;
            }

            // stylelint-disable-next-line no-descending-specificity
            .checkbox:focus .check {
                outline: 2px solid $element-focus;
            }

            // stylelint-disable-next-line no-descending-specificity
            input:focus-visible + .check {
                outline: 2px solid $element-focus;
            }
        }

        .range {
            display: grid;
            grid-template-areas:
                'min-value slider slider slider slider slider max-value'
                '. . min-input separator max-input . .';
            grid-template-columns: auto 1fr auto auto auto 1fr auto;
            gap: 0;
            align-items: center;

            .min-value {
                grid-area: min-value;
            }

            .b-slider {
                grid-area: slider;
                padding: 0 1rem;

                .b-slider-thumb:focus {
                    outline: 2px solid $element-focus;
                }
            }

            .max-value {
                grid-area: max-value;
            }

            .min-input {
                grid-area: min-input;
            }

            .hyphen {
                grid-area: separator;
            }

            .max-input {
                grid-area: max-input;
            }

            .field {
                margin: 0;

                .b-numberinput input.input:focus-visible {
                    outline: 2px solid $element-focus;
                    border-color: transparent;
                }
            }
        }
    }

    &:nth-child(2n) {
        background-color: $white-ter;
    }

    &.feature-type-excluded {
        .collapse-content {
            opacity: 0.7;
        }
    }
}
</style>
