<template>
    <div
        v-if="showPopup"
        class="cabd-popup"
        :class="{open: expandSidebar}"
    >
        <div class="popup-title">
            <div
                role="form"
                aria-label="Choose details view"
                class="actions"
            >
                <div>
                    <a
                        role="button"
                        aria-label="Simple View"
                        :class="{active: currentView === 'simple'}"
                        tabindex="600"
                        @keypress.enter="currentView = 'simple'"
                        @click="currentView = 'simple'"
                    >{{ $t('SIMPLE_VIEW') }}</a>
                    <a
                        role="button"
                        aria-label="Detailed View"
                        :class="{active: currentView === 'detailed'}"
                        tabindex="601"
                        @keypress.enter="currentView = 'detailed'"
                        @click="currentView = 'detailed'"
                    >{{ $t('DETAILED_VIEW') }}</a>
                </div>
                <!-- <a
                    role="button"
                    aria-label="Detailed View"
                    :class="{active: currentView === 'update'}"
                    tabindex="602"
                    @keypress.enter="currentView = 'update'"
                    @click="currentView = 'update'"
                >{{ $t('UPDATE_INFORMATION') }}</a> -->
            </div>
            <h2>
                {{ featureName }}
            </h2>
            <h3>
                {{ $t(featureType.toUpperCase()) }}
            </h3>
            <b-button
                icon-left="close"
                alt="Close Dialog"
                title="Close Dialog"
                class="close-button"
                type="is-ghost"
                aria-label="Close Popup"
                tabindex="602"
                @keypress.enter="$emit('close')"
                @click.native="$emit('close')"
            />
            <div class="show-close-button-focus" />
        </div>
        <div
            role="list"
            aria-label="Map Popup Details"
            class="cabd-content"
        >
            <template
                v-for="attr in viewProps"
            >
                <div
                    v-if="!(attr.id.endsWith('_code'))"
                    :key="attr.id"
                    class="p-1"
                    :class="attr.class"
                    role="listitem"
                >
                    <span
                        class="key-title"
                        :title="attr.description"
                    >
                        {{ attr.name }}
                    </span>
                    <ul
                        v-if="Array.isArray(attr.value)"
                        class="value"
                    >
                        <li
                            v-for="value in attr.value"
                            :key="value"
                        >
                            {{ value }}
                        </li>
                    </ul>
                    <a
                        v-else-if="attr.id === 'datasource_url'"
                        :href="attr.value"
                        target="_blank"
                        class="value"
                    >
                        {{ $t('CLICK_DOWNLOAD') }}
                    </a>
                    <span
                        v-else
                        class="value"
                        :class="attr.class"
                    >
                        {{ attr.value }}
                    </span>
                </div>
            </template>
            <template>
                <div
                    v-show="currentView === 'update'"
                    class="update-container"
                >
                    <span class="key-title">
                        {{ $t('BARRIER_IDENTIFIER') }}
                    </span>
                    <b-input
                        :placeholder="getPropertyObject(properties.feature_type, 'cabd_id').value"
                        :value="getPropertyObject(properties.feature_type, 'cabd_id').value"
                        type="text"
                        disabled
                    />
                    <span class="key-title">
                        {{ $t('PLACEHOLDER_NAME') }}
                    </span>
                    <b-input
                        v-model="name"
                        type="text"
                    />
                    <span class="key-title">
                        {{ $t('PLACEHOLDER_ORGANIZATION') }}
                    </span>
                    <b-input
                        v-model="organization"
                        type="text"
                    />
                    <span class="key-title">
                        {{ $t('PLACEHOLDER_EMAIL') }}
                    </span>
                    <b-input
                        v-model="email"
                        type="email"
                    />
                    <span class="key-title">
                        {{ $t('PLACEHOLDER_DATA') }}
                    </span>
                    <b-input
                        v-model="datasource"
                        type="text"
                    />
                    <span class="key-title">
                        {{ $t('PLACEHOLDER_DESCRIPTION') }}
                    </span>
                    <textarea
                        v-model="description"
                        class="textarea mb-2"
                    />
                    <p
                        v-if="statusMessage"
                        class="status-message mb-2"
                        :class="status"
                    >
                        {{ statusMessage }}
                    </p>
                    <b-button
                        type="primary"
                        class="submit-button"
                        @click="putData"
                    >
                        {{ $t('SUBMIT') }}
                    </b-button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import formatcoords from 'formatcoords';
import axios from 'axios';
import csvIcon from '@/assets/csv-icon.svg';

// want a special title or format to the values? put it in here
const PROPERTY_FORMATS = {
    // for changing a title, only supply the string value of the resulting title.
    latitude: {
        // gotta use a fake longitude to make format coords recognize the latitude
        value: (str) => formatcoords(+str, -120).format('-d', { decimalPlaces: 4, latLonSeparator: ' ' }).split(' ')[0],
    },
    longitude: {
        // ditto for longitude
        value: (str) => formatcoords(40, +str).format('-d', { decimalPlaces: 4, latLonSeparator: ' ' }).split(' ')[1],
    },
    data_source_id: {
        class: 'extra-width',
    },
    cabd_id: {
        class: 'extra-width',
    },
};

export default {
    name: 'MapPopup',

    props: {
        selectedFeature: {
            type: Object,
            required: true,
        },
        showPopup: {
            type: Boolean,
            required: true,
        },
        featureTypes: {
            type: Array,
            required: true,
        },
        featureTypeDefinitions: {
            type: Object,
            required: true,
        },
        expandSidebar: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            currentView: 'simple',
            csvIcon,
            name: '',
            email: '',
            organization: '',
            description: '',
            datasource: '',
            status: null,
            statusMessage: '',
        };
    },

    computed: {
        viewProps() {
            if (this.currentView === 'simple') {
                return this.featureTypeDefinitions[this.properties.feature_type].views.limited.attribute_order.map(
                    (key) => this.getPropertyObject(this.properties.feature_type, key),
                );
            }
            if (this.currentView === 'detailed') {
                return this.featureTypeDefinitions[this.properties.feature_type].views.all.attribute_order.map(
                    (key) => this.getPropertyObject(this.properties.feature_type, key),
                );
            }
            return {};
        },
        properties() {
            return this.selectedFeature.properties;
        },
        featureName() {
            // find the first name object we can use
            const featureName = this.selectedFeature.properties.dam_name_en
                || this.selectedFeature.properties.dam_name_fr
                || this.selectedFeature.properties.name_en
                || this.selectedFeature.properties.name_fr
                || this.selectedFeature.properties.fall_name_en
                || this.selectedFeature.properties.fall_name_fr
                || this.selectedFeature.properties.structure_name_en
                || this.selectedFeature.properties.structure_name_fr;

            return featureName || this.$t('UNNAMED');
        },
        featureType() {
            return this.featureTypes.find((el) => el.type === this.selectedFeature.properties.feature_type).name;
        },
    },

    watch: {
        selectedFeature() {
            this.currentView = 'simple';
            this.description = '';
            this.statusMessage = '';
        },
    },

    methods: {
        /**
         * Get a formatted title and value for a specific property denoted by key
         *
         * @param {String} featureType Feature type, so we can figure out how to format this attribute.
         * @param {String} key The key name for the property we want to get a tuple of
         */
        getPropertyObject(featureType, key) {
            const prop = {
                ...(this.featureTypeDefinitions[featureType].attributes.find((e) => e.id === key)),
                value: this.formatValue(key),
            };
            if (key in PROPERTY_FORMATS && PROPERTY_FORMATS[key].class) {
                prop.class = PROPERTY_FORMATS[key].class;
            }
            return prop;
        },

        /**
         * Formats a value as per either the PROPERTY_FORMATS const. Will show a ' - ' for undefined or null values.
         *
         * @param {String} key The key name for the property
         */
        formatValue(key) {
            if (!this.properties[key]) {
                return ' - ';
            }
            if (key in PROPERTY_FORMATS && PROPERTY_FORMATS[key].value) {
                return PROPERTY_FORMATS[key].value(this.properties[key]);
            }
            return this.properties[key];
        },


        /**
         * Submit PUT request to update information
         */
        async putData() {
            const myId = this.getPropertyObject(this.properties.feature_type, 'cabd_id').value;
            const myData = {
                name: this.name,
                email: this.email,
                organization: this.organization,
                description: this.description,
                datasource: this.datasource,
            };

            if (myData.name && myData.email && myData.description) {
                try {
                    const res = await axios.put(`${this.$config.CABD_API}features/${myId}`, myData);
                    if (res.status === 200) {
                        this.status = 'success';
                        this.statusMessage = this.$t('UPLOAD_SUCCESS');
                    } else {
                        this.status = 'failure';
                        this.statusMessage = this.$t('UPLOAD_FAILURE');
                    }
                } catch (e) {
                    this.status = 'failure';
                    this.statusMessage = this.$t('UPLOAD_FAILURE');
                }
            } else {
                this.status = 'failure';
                this.statusMessage = this.$t('UPLOAD_MISSING');
            }
        },
    },
};
</script>

<style lang="scss">
.cabd-popup {
    width: 30rem;
    position: absolute;
    bottom: 2rem;
    left: calc(50% - 10rem);
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.4);
    font-family: 'Lato', sans-serif;
    text-align: left;
    color: black;
    font-size: 12pt;
    z-index: 10;

    &.open {
        left: calc(50% + 5rem);
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-weight: 600;
    }

    .cabd-content {
        padding: 1.2rem;
        overflow-y: auto;
        overflow-x: hidden;
        display: grid;
        height: 28rem;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        padding-right: 0;
        align-content: start;
        row-gap: 0.5rem;

        div {
            display: inline-grid;
            height: auto;
            grid-auto-columns: auto;
            padding-bottom: 0.5rem;

            &.extra-width {
                grid-column-start: 1;
                grid-column-end: 3;
            }

            span {
                font-family: 'Lato', sans-serif;

                width: 9rem;
                word-wrap: break-word;

                &.extra-width {
                    width: 18rem;
                }

                &.key-title {
                    font-weight: 600;
                    color: $cabd-grey;
                    padding-bottom: 0.2rem;
                    text-transform: uppercase;
                    font-size: 0.75em;
                    width: 100%;
                }
            }

            .value {
                font-size: 0.8em;
                font-weight: 600;
            }

            ul.value {
                list-style: disc;
                font-size: 0.7em;
                margin-left: 1.2em;
            }

            a.value {
                color: $primary;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .popup-title {
        border-bottom: 1px solid $grey-light;
        padding: 1rem;

        h2 {
            font-size: 1em;
            font-weight: 600;
            font-family: 'Raleway', sans-serif;
        }

        h3 {
            font-size: 0.7em;
            font-weight: 500;
            padding: 0;
        }

        .actions {
            float: right;
            padding-right: 1.5rem;

            a {
                border: 2px solid transparent;
                padding: 0 0.4rem 0.15rem;
                color: #aaa;

                &.active {
                    color: $cabd-red;
                    cursor: default;
                }

                &:focus-visible {
                    outline: none;
                    border-color: $element-focus;
                }
            }
        }

        .close-button {
            position: absolute;
            top: 0;
            right: 0;
            color: $cabd-red;
            cursor: pointer;
            box-shadow: none;
            border: none;
            outline: none;
        }

        .show-close-button-focus {
            position: absolute;
            top: 5px;
            right: 5px;
            height: 1.75rem;
            width: 1.75rem;
            border: 2px solid transparent;
            pointer-events: none;
        }

        .close-button:focus-visible + .show-close-button-focus {
            border-color: $element-focus;
        }

        .close-button:hover + .show-close-button-focus {
            border-color: #aaa;
            border-radius: 3px;
        }
    }
}

.update-container {
    display: flex;
    width: 27.6rem;
}

.extra-large {
    height: 20rem;
}

.submit-button {
    background-color: $primary;
    color: $light;
    font-size: 1.2rem;
    font-weight: bold;
    &:hover {
        color: $light;
    }
    &:focus {
        color: $light;
    }
}

.status-message {
    border-radius: 0.5em;
    padding: 1em;
}

.success {
    background-color: lightgreen;
    border: 1px solid $primary-light;
}

.failure {
    background-color: pink;
    border: 1px solid $cabd-red;
}
</style>
