import { extend as extendValidator, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
    },

    created() {
        // pattern to match string that look like numbers:
        // includes negatives and decimals
        const numberRegex = new RegExp(/^-?[\d.]+$/);

        // validation rule: required field
        extendValidator('required', {
            ...required,
            message: this.$t('FIELD_REQUIRED'),
        });

        // validation rule: maximum numeric value
        // This used to be used for lat/lon. Not anymore
        // extendValidator('between', {
        //     ...between,
        //     message: this.$t('FIELD_REQUIRED_BETWEEN', 'max', 'min'),
        // });

        // validation rule: number or numeric string
        extendValidator('number', {
            validate(value) {
                return numberRegex.test(value)
            // also test against NaN
            && Number.isFinite(Number(value));
            },
            message: this.$t('FIELD_REQUIRED_NUMBER'),

        });

        extendValidator('lat_lon', {
            validate(value) {
                const [lat, lon] = value.split(',').map((str) => str.trim());
                if (!lat || !lon || Number.isNaN(+lat) || Number.isNaN(+lon)) return false;
                if (Math.abs(lat) > 90) return false;
                if (Math.abs(lon) > 180) return false;
                return true;
            },
            message: this.$t('FIELD_REQUIRED_LAT_LON'),
        });
    },
};
