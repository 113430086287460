<template lang="html">
    <div
        :id="id"
        class="mapbox-gl-map"
    />
</template>

<script>
import formatcoords from 'formatcoords';
import mapboxgl from 'mapbox-gl';

import foundryLogo from '@/assets/foundryLogo.svg';
import refractionsLogo from '@/assets/refractionsLogo.svg';
import LocatorControl from '@/helpers/LocatorControl';
import SwitcherControl from '@/helpers/SwitcherControl';

export default {
    name: 'MapboxGlMap',

    props: {
        id: {
            type: String,
            default: 'map',
        },
        options: {
            type: Object,
            required: true,
        },
        accessToken: {
            type: String,
            default: process.env.VUE_APP_MAPBOX_TOKEN,
        },
        zoomControl: {
            type: Boolean,
            default: false,
        },
        dragRotate: {
            type: Boolean,
            default: false,
        },
        styleToggle: {
            type: Boolean,
            default: false,
        },
        scaleAndCoordinates: {
            type: Boolean,
            default: true,
        },
        styleSwitchOptions: {
            type: Array,
            required: false,
            default: () => [],
        },
    },

    data() {
        return {
            map: null,
            locator: null,
        };
    },

    computed: {
        scaleWidth() {
            return document.getElementsByClassName('mapboxgl-ctrl-scale')[0].style.width;
        },
    },

    mounted() {
        // initialize MapboxGL map with the given options sent through the prop
        mapboxgl.accessToken = this.accessToken;
        const myLanguage = this.$route.params.locale === 'en' ? 'en' : 'ca-fr';
        this.map = new mapboxgl.Map({
            container: this.id,
            attributionControl: false,
            minZoom: 0,
            maxZoom: 20,
            language: myLanguage,
            ...this.options,
        });

        // add custom attribution control
        this.map.addControl(new mapboxgl.AttributionControl({
            customAttribution: `
                <a
                    target="_blank"
                    href="https://www.foundryspatial.com"
                />
                    Foundry Spatial
                    <img
                        style="margin: -3px 0 -3px 2px; width: 15px;"
                        alt="Foundry Spatial logo"
                        src="${foundryLogo}"
                    >
                </a>
                |
                <a
                    target="_blank"
                    href="http://www.refractions.net/"
                />
                    Refractions Research Inc.
                    <img
                        style="margin: -3px 0 -3px 2px; width: 15px;"
                        alt="Refractions Research Inc. logo"
                        src="${refractionsLogo}"
                    >
                </a>
            `,

        }));

        if (this.scaleAndCoordinates) {
            this.locator = new LocatorControl();
            this.map.addControl(this.locator);
        }

        // add style switchers
        if (this.styleSwitchOptions.length > 1) {
            // build up some actions and set the system up to change styles when the user clicks the various buttons
            this.map.addControl(new SwitcherControl({
                actions: this.styleSwitchOptions,
                switcherSize: 26,
            }), 'bottom-right');
        }

        // add zoom control
        if (this.zoomControl) {
            const options = {
                // hide the compass button if dragRotate is disabled
                showCompass: this.dragRotate,
            };
            this.map.addControl(new mapboxgl.NavigationControl(options), 'bottom-right');
        }

        // disable dragRotate interaction handler
        if (!this.dragRotate) {
            this.map.dragRotate.disable();
            this.map.touchZoomRotate.disableRotation();
        }

        // emit "load" when all downloads are done and the first map render is done
        // (fires once during map lifecycle)
        this.map.on('load', (ev) => this.emitEvent('load', ev));

        // handle error events
        this.map.on('error', (ev) => this.emitEvent('error', ev));

        // emit "idle" when tile loading, animations, and movement are all finished
        // (fires many times during map lifecycle)
        this.map.on('idle', (ev) => this.emitEvent('idle', ev));

        // emit movement and zoom events
        this.map.on('move', (ev) => {
            if (this.locator) {
                this.locator.updateScale();
            }
            this.emitEvent('move', ev);
        });
        this.map.on('moveend', (ev) => this.emitEvent('moveend', ev));
        this.map.on('zoom', (ev) => {
            if (this.locator) {
                this.locator.updateScale();
            }
            this.emitEvent('zoom', ev);
        });
        this.map.on('mousemove', (ev) => {
            this.emitEvent('mousemove', ev);
        });
        this.map.on('zoomend', (ev) => this.emitEvent('zoomend', ev));

        // emit "click" on any map click
        this.map.on('click', (ev) => this.emitEvent('click', ev));

        if (this.scaleAndCoordinates) {
            this.map.on('mousemove', (ev) => {
                if (this.locator) {
                    const coords = formatcoords(ev.lngLat).format('-d', { decimalPlaces: 4, latLonSeparator: ' ' }).split(' ');
                    this.locator.setCoordTexts(coords);
                }
            });
            if (this.locator) {
                this.locator.updateScale();
            }
        }

        // emit "render" events
        this.map.on('render', (ev) => this.emitEvent('render', ev));

        // emit "data" on map data event
        this.map.on('data', (ev) => this.emitEvent('data', ev));

        // emit "styledata" on map styledata events
        // this can be used when the style changes
        this.map.on('styledata', (ev) => this.emitEvent('styledata', ev));
    },

    beforeDestroy() {
        if (this.map) {
            // run mapbox method to clean up and release all of the map's resources. Most importantly, this releases
            // the map's webgl context. Most browsers can only render up to 16 webgl contexts, before the first one
            // will be removed.
            this.map.remove();
        }
    },

    methods: {
        /**
         * re-emit mapbox-gl events as vue events
         * including the event and the map object
         * @param  {String}         name event name
         * @param  {mapboxgl.Event} ev   mapbox-gl event object
         */
        emitEvent(name, ev) {
            this.$emit(name, ev, this.map);
        },
    },
};
</script>

<style lang="scss">
// import mapbox gl styles
@import "~mapbox-gl/dist/mapbox-gl.css";
@import "~@/helpers/LocatorControl.css";
@import "~@/helpers/SwitcherControl.css";

.mapbox-gl-map {
    // default sizing
    width: 100vw;
    height: 100vh;

}

canvas.mapboxgl-canvas {
    width: 100vw !important;
    height: 100vh !important;
}

.mapboxgl-ctrl-bottom-right {
    right: 1em;
}

.mapboxgl-ctrl-top-right {
    right: 1em;

    .mapboxgl-ctrl-locator {
        background-color: rgba(0, 0, 0, 0.6);
    }
}
</style>
